<template>
  <div>
    <Navbar v-if="navVisible" />
    <router-view :key="$route.fullPath" />
    <Footer v-if="footerVisible" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: { Navbar, Footer },
  computed: {
    ...mapState(["navVisible", "footerVisible"]),
  },
};
</script>
