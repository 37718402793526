const mutations = {
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("accesstoken", data);
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.isAdmin = false;
    state.email = "";
    state.mobileNo = "";
    state.username = "";
    state.id = null;
    state._id = null;
    localStorage.removeItem("accesstoken");
  },
  SET_NAVBAR(state, data) {
    state.navbarState = data;
  },
  SET_NAVBAR_VISIBLE(state, data) {
    state.navVisible = data;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data;
  },
  SET_USER_FUNDING(state, data) {
    state.userFundingList = data;
  },
  SET_USER_INFO(state, data) {
    if (data.email) {
      state.email = data.email;
    }
    if (data.id) {
      state.id = data.id;
    }
    if (data.userId) {
      state.userId = data.userId;
    }

    if (data.avatar) {
      state.avatar = data.avatar;
    }

    if (data.isAdmin) {
      state.isAdmin = data.isAdmin;
    }
    if (data.mobileNo) {
      state.mobileNo = data.mobileNo;
    }

    if (data.username) {
      state.username = data.username;
    }
    if (data.surveys) {
      state.surveys = data.surveys;
    }
    if (data.level) {
      state.level = data.level;
    }
    if (data.point) {
      state.point = data.point;
      if (data.point > 199) {
        state.level = "DIAMOND";
      } else if (data.point > 99) {
        state.level = "GOLD";
      } else if (data.point < 51) {
        state.level = "SILVER";
      }
    }
  },
};
export default mutations;
