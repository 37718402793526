const state = {
  id: null,
  userId: null,
  email: "", //이메일
  status: "",
  mobileNo: "",
  username: "",
  level: "",
  point: 0,
  surveys: [],
  isLogin: false,
  navVisible: true,
  footerVisible: true,
  navbarState: 0,
  isAdmin: false,
  userFundingList: [],
};

export default state;
