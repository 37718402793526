import "babel-polyfill";
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "@/styles/common.scss";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import { Collapse, InfiniteScroll, Loading } from "element-ui";
import locale from "element-ui/lib/locale/lang/ko";

import Datepicker from "vuejs-datepicker";
import Tabs from "vue-material-tabs";
import VueCountdown from "@chenfengyuan/vue-countdown";

Vue.config.productionTip = true;
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(ElementUI, { locale });

Vue.use(Collapse);
Vue.use(InfiniteScroll);
Vue.use(Loading);
Vue.use(Datepicker);
Vue.use(Tabs);

new Vue({
  router,
  store,
  beforeCreate() {
    if (this.$store.state.isLogin) {
      this.$store.dispatch("getAccountInfo");
    }
  },
  render: (h) => h(App),
}).$mount("#app");
