import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
//   next();
// };

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken && store.state.isAdmin == true) {
    next();
  } else {
    // next();
    next("/");
  }
};

// const redirect = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken) {
//     next("/");
//   } else {
//     next();
//   }
// };

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  // {
  //   path: "/home2",
  //   name: "home2",
  //   component: () => import("../views/Home2.vue"),
  // },
  {
    path: "/intro",
    name: "intro",
    component: () => import("../views/Intro.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
        // beforeEnter: redirect(),
      },
      {
        path: "/join",
        name: "join",
        component: () => import("../views/account/Join.vue"),
        // beforeEnter: redirect(),
      },
      {
        path: "/joinDone",
        name: "joinDone",
        component: () => import("../views/account/JoinDone.vue"),
      },
      {
        path: "/findEmail",
        name: "findEmail",
        component: () => import("../views/account/FindEmail.vue"),
      },
      {
        path: "/findEmailDone",
        name: "findEmailDone",
        component: () => import("../views/account/FindEmailDone.vue"),
      },
      {
        path: "/findPassword",
        name: "findPassword",
        component: () => import("../views/account/FindPassword.vue"),
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: () => import("../views/account/ChangePassword.vue"),
      },
      {
        path: "/changePasswordDone",
        name: "changePasswordDone",
        component: () => import("../views/account/ChangePasswordDone.vue"),
      },
    ],
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/account/Login.vue"),
  //   // beforeEnter: redirect(),
  // },
  // {
  //   path: "/join",
  //   name: "join",
  //   component: () => import("../views/account/Join.vue"),
  //   // beforeEnter: redirect(),
  // },
  // {
  //   path: "/joinDone",
  //   name: "joinDone",
  //   component: () => import("../views/account/JoinDone.vue"),
  // },
  // {
  //   path: "/findEmail",
  //   name: "findEmail",
  //   component: () => import("../views/account/FindEmail.vue"),
  // },
  // {
  //   path: "/findEmailDone",
  //   name: "findEmailDone",
  //   component: () => import("../views/account/FindEmailDone.vue"),
  // },
  // {
  //   path: "/findPassword",
  //   name: "findPassword",
  //   component: () => import("../views/account/FindPassword.vue"),
  // },
  // {
  //   path: "/changePassword",
  //   name: "changePassword",
  //   component: () => import("../views/account/ChangePassword.vue"),
  // },
  // {
  //   path: "/changePasswordDone",
  //   name: "changePasswordDone",
  //   component: () => import("../views/account/ChangePasswordDone.vue"),
  // },

  {
    path: "/survey/detail/:id",
    name: "surveyDetail",
    component: () => import("../views/survey/Detail.vue"),
  },
  {
    path: "/survey/history/:id",
    name: "surveyHistory",
    component: () => import("../views/survey/History.vue"),
  },
  {
    path: "/survey/done",
    name: "surveyDone",
    component: () => import("../views/survey/SurveyDone.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("../views/survey/Guide.vue"),
  },
  {
    path: "/survey/list/:category",
    name: "surveyList",
    component: () => import("../views/survey/List.vue"),
  },
  {
    path: "/request",
    name: "request",
    component: () => import("../views/survey/Request.vue"),
  },

  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
    // beforeEnter: requireAdmin(),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),
    beforeEnter: requireAdmin(),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/Users.vue"),
      },
      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("../views/admin/UserDetail.vue"),
      },
      {
        path: "posts",
        name: "posts",
        component: () => import("../views/admin/Posts.vue"),
      },
      {
        path: "posts/register",
        name: "postsRegister",
        component: () => import("../views/admin/PostRegister.vue"),
      },
      {
        path: "posts/detail/:id",
        name: "postDetail",
        component: () => import("../views/admin/PostDetail.vue"),
      },
      {
        path: "reviews",
        name: "reviews",
        component: () => import("../views/admin/Reviews.vue"),
      },
      {
        path: "reviews/detail/:id",
        name: "reviewsDetail",
        component: () => import("../views/admin/ReviewDetail.vue"),
      },
    ],
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("../views/mypage/Mypage.vue"),
    // beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/edit",
    name: "edit",
    component: () => import("../views/mypage/UserInfo.vue"),
    // beforeEnter: requireAuth(),
  },

  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms/UseTerms.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/terms/TermsPrivacy.vue"),
  },
  {
    path: "*",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
