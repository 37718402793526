<template>
  <nav>
    <router-link to @click.native="handleMove('/')" class="moLogo">
      <img src="@/assets/images/logo.png" alt="logo" />
    </router-link>
    <button class="moBtn" @click="handleOpen">
      <img src="@/assets/image/menu-black.svg" alt="menu" />
    </button>
    <div class="inner flexB">
      <div class="menuLogo">
        <router-link to @click.native="handleMove('/')">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
      </div>
      <ul>
        <!-- <li class="menuLogo">
          <router-link to @click.native="handleMove('/')">
            <img src="@/assets/images/logo.png" alt="logo" />
          </router-link>
        </li> -->
        <!-- <li @click="handelAlert"><router-link to="">기획전</router-link></li> -->
        <li>
          <router-link to @click.native="handlePage('SPECIAL')">스페셜</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('TECH')">테크/가전</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('FASHION')">패션/잡화</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('BEAUTY')">뷰티</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('FOOD')">푸드</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('HOMELIVING')">홈리빙</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('TRIP')">여행/레저</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('SPORTS')">스포츠/모빌리티</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('CULTURE')">공연/컬쳐</router-link>
        </li>
        <li>
          <router-link to @click.native="handlePage('KIDS')">베이비/키즈</router-link>
        </li>
        <li>
          <router-link to @click.native="handleMove('/intro')">서비스 소개<span>*</span></router-link>
        </li>
        <li>
          <router-link to @click.native="handleMove('/guide')">이용 안내<span>*</span></router-link>
        </li>
        <!-- <li>
          <router-link to @click.native="handleMove('/investfunding')"
            >투자 설문<span>*</span></router-link
          >
        </li>
        <li @click="handelAlert"><router-link to="">오픈 마켓</router-link></li>
        <li>
          <router-link to @click.native="handleMove('/qna')"
            >Q &#38; A</router-link
          >
        </li> -->
        <li class="moMenu" v-if="isLogin">
          <router-link to @click.native="handleMove('/mypage')">마이페이지</router-link>
        </li>
      </ul>
      <ul class="moMenu">
        <li @click="handleFilter()">
          <router-link to="#none" class="down">카테고리<span class="material-icons"> expand_more </span></router-link>
          <ul class="sub">
            <li>
              <router-link to @click.native="handlePage('SPECIAL')">스페셜</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('TECH')">테크/가전</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('FASHION')">패션/잡화</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('BEAUTY')">뷰티</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('FOOD')">푸드</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('HOMELIVING')">홈리빙</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('TRIP')">여행/레저</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('SPORTS')">스포츠/모빌리티</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('CULTURE')">공연/컬쳐</router-link>
            </li>
            <li>
              <router-link to @click.native="handlePage('KIDS')">베이비/키즈</router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to @click.native="handleMove('/intro')">서비스 소개<span>*</span></router-link>
        </li>
        <li>
          <router-link to @click.native="handleMove('/guide')">이용 안내<span>*</span></router-link>
        </li>
        <li v-if="isLogin">
          <router-link to @click.native="handleMove('/mypage')">마이페이지</router-link>
        </li>
      </ul>
      <ul>
        <!-- <li class="joinMenu" v-if="!isLogin">
          <router-link to="/request">회원가입</router-link>
        </li> -->
        <li class="joinMenu">
          <router-link to="/request">설문 의뢰</router-link>
        </li>
        <li class="joinMenu" v-if="isAdmin">
          <router-link to="/admin/users">ADMIN</router-link>
        </li>
        <li class="joinMenu" v-if="isLogin">
          <router-link to="/mypage">마이페이지</router-link>
        </li>
        <li class="joinMenu" v-if="!isLogin">
          <router-link to="/join">회원가입</router-link>
        </li>
        <li class="loginMenu" v-if="!isLogin">
          <router-link to @click.native="handleMove('/login')"> 로그인</router-link>
          <router-link to @click.native="handleMove('/join')" class="mobile"> 회원가입</router-link>
        </li>
        <li class="loginMenu" v-if="isLogin">
          <router-link to @click.native="handleMove('/mypage')" class="info">
            <!-- <p>홍길동<img src="@/assets/images/gold.svg" alt="" /></p> -->
            <p>{{ username }}</p>
            <p>{{ email }}</p>
            <p class="mypoint">{{ point }}P</p>
            <img v-if="level == 'SILVER'" src="@/assets/images/silver.svg" alt="" />
            <img v-if="level == 'GOLD'" src="@/assets/images/gold.svg" alt="" />
            <img v-if="level == 'DIAMOND'" src="@/assets/images/dia.svg" alt="" />
          </router-link>
        </li>
        <!-- <li class="loginMenu" v-if="isLogin">
          <p>{{ username }}</p>
          <p>{{ email }}</p>
        </li> -->
      </ul>
      <button class="moClose" @click="handelClose">
        <img src="@/assets/images/close-black.svg" alt="close" />
      </button>
    </div>
    <div class="dimBg" @click="handelClose"></div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { baseUrl } from "@/config/env";
export default {
  data() {
    return {
      baseUrl: baseUrl,
    };
  },
  computed: {
    ...mapState(["isLogin", "username", "email", "isAdmin", "level", "point"]),
  },
  methods: {
    handleLogout() {
      let result = confirm("로그아웃 하시겠습니까?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },
    handleMove(path) {
      this.$router.push(path);
      this.handelClose();
    },
    handlePage(category) {
      this.$router.push({ name: "surveyList", params: { category: category } });
      this.handelClose();
    },
    handleOpen() {
      document.querySelector(".inner").classList.add("active");
      document.querySelector(".inner").classList.remove("hide");
    },
    handelClose() {
      document.querySelector(".inner").classList.add("hide");
      document.querySelector(".inner").classList.remove("active");
    },
    handelAlert() {
      return alert("준비중입니다.");
    },
    handleFilter() {
      document.querySelector(".down").classList.toggle("active");
      document.querySelector(".sub").classList.toggle("active");
    },
  },
};
</script>
