import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function createUser(data) {
  return axios.post(baseUrl + "/accounts/join", data, { headers });
}

export function fetchUserDetails(userId) {
  return axios.get(baseUrl + `/accounts/detail/${userId}`, { headers });
}

export function fetchProfile() {
  return axios.get(baseUrl + `/accounts/profile`, { headers });
}

export function deleteUser(userId) {
  return axios.delete(baseUrl + `/admin/user/${userId}`, {
    headers,
  });
}

export function updateUser(data) {
  return axios.put(baseUrl + `/accounts/profile`, data, {
    headers,
  });
}

export function fetchUserList(params) {
  return axios.get(baseUrl + `/admin/userList`, { params, headers });
}

export function login(data) {
  return axios.post(baseUrl + "/accounts/login", data, { headers });
}

export function createAccountId(data) {
  return axios.post(baseUrl + "/accounts/findId", data);
}

export function findAccountPw(data) {
  return axios.post(baseUrl + "/accounts/resetPwd", data);
}

export function findAccountID(data) {
  return axios.post(baseUrl + "/accounts/find/accountId", data);
}
export function fetchBookmark(params) {
  return axios.get(baseUrl + `/accounts/bookmark`, { params, headers });
}

//회원탈퇴
export function createWithdraw() {
  return axios.get(baseUrl + "/accounts/withdraw", { headers });
}

export function updateUserPwd(data) {
  return axios.put(baseUrl + `/accounts/user/pwd`, data, {
    headers,
  });
}

export function updatePwd(data) {
  return axios.put(baseUrl + `/accounts/pwd`, data, {
    headers,
  });
}

export function updateBanner(data) {
  return axios.post(baseUrl + `/admin/banner`, data, {
    headers,
  });
}

export function fetchBanners() {
  return axios.get(baseUrl + "/home/banner", { headers });
}

export function deleteBanner(id, url) {
  return axios.delete(baseUrl + `/file/banner/${id}?url=${url}`, {
    headers,
  });
}

export function createCertNumber(data) {
  return axios.post(baseUrl + `/auth/sms`, data, { headers });
}
export function verifyMobile(data) {
  return axios.post(baseUrl + `/auth/sms/verify`, data, { headers });
}
