import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function createFunding(data) {
  return axios.post(baseUrl + `/funding`, data, { headers });
}

export function fundingCancel(data) {
  return axios.put(baseUrl + `/funding/cancel`, data, { headers });
}

export function fetchUserFundingList(params) {
  return axios.get(baseUrl + `/survey/list/user`, { params, headers });
}
